import React, { useEffect, useState } from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/sections/admin/authentication/LoginPage_BG.png";
import LP_logo from "../../assets/sections/admin/authentication/LP_logo.svg";
import eye_closed from "../../assets/sections/admin/authentication/eye_closed.svg";
import eye_open from "../../assets/sections/admin/authentication/eye_open.svg";
import passwordIcon from "../../assets/sections/admin/authentication/password.svg";
import backArrow from "../../assets/common/back_arrow.svg";

import Axios from "../../axios";

import { useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [shownewConfirm, setShownewConfirm] = useState(false);

  const navigate = useNavigate();

  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  const handleChangePassword = async () => {
    if (password === "" || newPassword === "") {
      // alert("Please fill all the fields");
      setError("Please fill all the fields");
    } else {
      let loginData = {
        currentPassword: password,
        newPassword: newPassword,
      };

      let url = null;
      let method = null;

      if (localStorage.getItem("userType") === "TENANT") {
        url = "/api/v1/tenant/changePassword";
        method = "post";
      } else if (localStorage.getItem("userType") === "ADMIN") {
        url = "/api/v1/admin/changePassword";
        method = "put";
      }

      let config = {
        method: method,
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        data: JSON.stringify(loginData),
      };

      try {
        const response = await Axios.request(config);

        if (response.status === 200 || response.status === 201) {
          navigate("/login");
        }
      } catch (error) {
        console.log("error ", error);

        if (error.response?.data?.message) {
          setError(error.response.data.message);
        }

        // Check if the error has a response with a status code
        if (error.response && error.response.status === 701) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userType");
        }
      }
    }
  };

  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={LP_logo} alt="LP_logo" />
        </div>
        <div className={styles.form_section}>
          <div className={styles.form}>
            <div className={styles.form_title}>
              <img
                src={backArrow}
                onClick={() => {
                  navigate(-1);
                }}
                alt=""
              />
              {"   "} Change Your Password
            </div>
            <div className={styles.form_components}>
              <div className={styles.form_component}>
                <div className={styles.title}>Current Password</div>
                <div className={styles.input_container}>
                  <img src={passwordIcon} alt="password" />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder={"**********************"}
                    className={styles.value + " " + styles.password_input}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError("");
                    }}
                  />
                  <img
                    className={styles.password_reveal}
                    src={showPassword ? eye_open : eye_closed}
                    alt="eye_closed"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className={styles.form_component}>
                <div className={styles.title}>New Password</div>
                <div className={styles.input_container}>
                  <img src={passwordIcon} alt="password" />
                  <input
                    type={shownewConfirm ? "text" : "password"}
                    placeholder={"**********************"}
                    className={styles.value + " " + styles.password_input}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setError("");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleChangePassword();
                      }
                    }}
                  />
                  <img
                    className={styles.password_reveal}
                    src={shownewConfirm ? eye_open : eye_closed}
                    alt="eye_closed"
                    onClick={() => setShownewConfirm(!shownewConfirm)}
                  />
                </div>
              </div>
              {<div className={styles.error_msg}>{error}</div>}
              <div>
                <button
                  className={styles.submit_button}
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChangePassword;
