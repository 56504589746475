import React, { useEffect, useState } from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/sections/admin/authentication/LoginPage_BG.png";
import LP_logo from "../../assets/sections/admin/authentication/LP_logo.svg";
import eye_closed from "../../assets/sections/admin/authentication/eye_closed.svg";
import eye_open from "../../assets/sections/admin/authentication/eye_open.svg";
import passwordIcon from "../../assets/sections/admin/authentication/password.svg";
import { jwtDecode } from "jwt-decode";

import Axios from "../../axios";

import { useNavigate, useParams } from "react-router-dom";
import { USER_TYPE, apiEndpointname } from "../../utils/constants";
import Footer from "./Footer";

function SetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const tempToken = useParams().tempToken;
  const navigate = useNavigate();

  useEffect(() => {
    if (tempToken === null || tempToken === undefined || tempToken === "") {
      navigate("/login");
    }
  }, [tempToken]);

  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const decodedToken = jwtDecode(tempToken);
      const userType = decodedToken?.userType;
      if (password === "" || passwordConfirm === "") {
        // alert("Please fill all the fields");
        setError("Please fill all the fields");
      } else if (password !== passwordConfirm) {
        setError("Password and Confirm Password must be same");
      } else {
        let loginData = {
          password: password,
          confirmPassword: passwordConfirm,
        };

        let apiUrl = "";
        if (userType === USER_TYPE.ADMIN || userType === USER_TYPE.EMPLOYEE) {
          apiUrl = apiEndpointname.EMPLOYEE_SETPASSWORD;
        } else if (userType === USER_TYPE.TENANT) {
          apiUrl = apiEndpointname.TENANT_SETPASSWORD;
        } else {
          alert("Unauthorized User");
          navigate("/login");
        }

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "/api/v1" + apiUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tempToken,
          },
          data: JSON.stringify(loginData),
        };

        try {
          const response = await Axios.request(config);

          if (response.status === 200 || response.status === 201) {
            // alert("Logged In Successfully");
            navigate("/login");
            // window.location.href = '/influencer';
            // window.open("/tenant/", "_self");
          }
        } catch (error) {
          console.log("error ", error);

          if (error.response?.data?.message) {
            setError(error.response.data.message);
          }

          // Check if the error has a response with a status code
          if (error.response && error.response.status === 701) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userType");
          }
        }
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={LP_logo} alt="LP_logo" />
        </div>
        <div className={styles.form_section}>
          <div className={styles.form}>
            <div className={styles.form_title}>Set Your Password</div>
            <div className={styles.form_components}>
              <div className={styles.form_component}>
                <div className={styles.title}>Password</div>
                <div className={styles.input_container}>
                  <img src={passwordIcon} alt="password" />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder={"**********************"}
                    className={styles.value + " " + styles.password_input}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError("");
                    }}
                  />
                  <img
                    className={styles.password_reveal}
                    src={showPassword ? eye_open : eye_closed}
                    alt="eye_closed"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <div className={styles.form_component}>
                <div className={styles.title}>Confirm Password</div>
                <div className={styles.input_container}>
                  <img src={passwordIcon} alt="password" />
                  <input
                    type={showPasswordConfirm ? "text" : "password"}
                    placeholder={"**********************"}
                    className={styles.value + " " + styles.password_input}
                    onChange={(e) => {
                      setPasswordConfirm(e.target.value);
                      setError("");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  <img
                    className={styles.password_reveal}
                    src={showPasswordConfirm ? eye_open : eye_closed}
                    alt="eye_closed"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  />
                </div>
              </div>
              {<div className={styles.error_msg}>{error}</div>}
              <div>
                <button className={styles.submit_button} onClick={handleLogin}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SetPassword;
