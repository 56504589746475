import React, { useState } from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/sections/admin/authentication/LoginPage_BG.png";
import LP_logo from "../../assets/sections/admin/authentication/LP_logo.svg";
import user from "../../assets/sections/admin/authentication/user.svg";

import Axios from "../../axios";

import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const handleLogin = async () => {
    if (email === "") {
      // alert("Please fill all the fields");
      setError("Please fill all the fields");
    } else {
      let loginData = {
        email: email,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/v1/auth/forgotPassword",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(loginData),
      };

      try {
        const response = await Axios.request(config);
        if (response.status === 200 || response.status === 201) {
          alert("Email sent successfully");
          navigate("/login");
        }
      } catch (error) {
        console.log("error ", error);
        if (error.response?.data?.message) {
          setError(error.response.data.message);
        }
      }
    }
  };

  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={LP_logo} alt="LP_logo" />
        </div>
        <div className={styles.form_section}>
          <div className={styles.form}>
            <div className={styles.form_title}>Forgot Password</div>
            <div className={styles.form_components}>
              <div className={styles.form_component}>
                <div className={styles.title}>Email address</div>
                <div className={styles.input_container}>
                  <img src={user} alt="user" />
                  <input
                    type="email"
                    placeholder={"Enter your email"}
                    className={styles.value}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError("");
                    }}
                  />
                </div>
              </div>
              {<div className={styles.error_msg}>{error}</div>}
              <div>
                <button className={styles.submit_button} onClick={handleLogin}>
                  Send Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
