import React, { useState } from "react";
import styles from "../../styles/authentication/Login.module.css";
import eye_closed from "../../assets/sections/admin/authentication/eye_closed.svg";
import eye_open from "../../assets/sections/admin/authentication/eye_open.svg";
import user from "../../assets/sections/admin/authentication/user.svg";
import passwordIcon from "../../assets/sections/admin/authentication/password.svg";

import Axios from "../../axios";

import OtpAuthentication from "./OtpAuthentication";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [featureAccess, setFeatureAccess] = useState([]);
  const [error, setError] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState("TENANT");
  const [isTenant, setIsTenant] = useState(false);

  const [formState, setFormState] = React.useState("login");

  const handleLogin = async () => {
    if (password === "" || email === "") {
      // alert("Please fill all the fields");
      setError("Please fill all the fields");
    } else {
      let loginData = {
        email: email,
        password: password,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/v1/auth/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(loginData),
      };

      try {
        const response = await Axios.request(config);

        if (response.status === 200 || response.status === 201) {
          if (response.data.success === true) {
            setIsSuperAdmin(
              response.data.userType === "ADMIN" ? "ADMIN" : "TENANT"
            );
            setIsTenant(response.data.userType === "TENANT" ? true : false);

            if (
              response.data.userType === "ADMIN" ||
              response.data.userType === "EMPLOYEE"
            ) {
              console.log("OTP Page");
              localStorage.setItem("userType", response.data.userType);
              setFeatureAccess(response?.data?.featureAccess); // Set the feature access for employee
              setFormState("otp");
            } else {
              const accessToken = response.headers.authorization.split(" ")[1];
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("userType", "TENANT");
              localStorage.setItem(
                "agreedOnRules",
                response?.data?.agreedOnRules
              );
              localStorage.setItem(
                "agreedOnDocument",
                JSON.stringify(response?.data?.agreedOnDocument)
              );
              window.open("/tenant/home", "_self");
            }
          } else {
            setError(response.data.message);
          }
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.log(error);

        if (error.response?.data?.message) {
          setError(error.response.data.message);
        }

        // Check if the error has a response with a status code
        if (error.response && error.response.status === 701) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userType");
        }
      }
    }
  };

  return formState === "login" ? (
    <div className={styles.form}>
      <div className={styles.form_title}>Account Login</div>
      <div className={styles.form_components}>
        <div className={styles.form_component}>
          <div className={styles.title}>Email address</div>
          <div className={styles.input_container}>
            <img src={user} alt="user" />
            <input
              type="email"
              placeholder={"Enter your email"}
              className={styles.value}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
          </div>
        </div>
        <div className={styles.form_component}>
          <div className={styles.title}>Password</div>
          <div className={styles.input_container}>
            <img src={passwordIcon} alt="password" />
            <input
              type={showPassword ? "text" : "password"}
              placeholder={"**********************"}
              className={styles.value + " " + styles.password_input}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            <img
              className={styles.password_reveal}
              src={showPassword ? eye_open : eye_closed}
              alt="eye_closed"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
        {<div className={styles.error_msg}>{error}</div>}
        <div>
          <button className={styles.submit_button} onClick={handleLogin}>
            Login
          </button>
        </div>

        <div className={styles.additional_info}>
          <div
            className={styles.forgot_password}
            onClick={() => {
              navigate("/forgotpassword");
            }}
          >
            Forgot Password ?
          </div>
        </div>

        {/* {isTenant && (
          <div className={styles.additional_info}>
            <div className={styles.forgot_password + " " + styles.highlight}>
              Forgot Password?
            </div>
            <span>|</span>
            <div className={styles.account_creation}>
              Don’t have an account ?{" "}
              <span className={styles.highlight}>Sign Up</span>
            </div>
          </div>
        )} */}
      </div>
      <Footer />
    </div>
  ) : (
    <OtpAuthentication email={email} featureAccess={featureAccess} />
  );
}

export default LoginForm;
