import React from "react";
import styles from "../../styles/authentication/Login.module.css";

function Footer() {
  return (
    <div className={styles.footer}>
      <p>
        Designed and Developed by :{" "}
        <a
          href="https://drivethrutechnologies.com.au/"
          target="_blank"
          rel="noreferrer"
        >
          Drive Thru Technologies
        </a>
      </p>
    </div>
  );
}

export default Footer;
